import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import "react-accessible-accordion/dist/fancy-example.css"

import "../../../../graphql/fragments"

import SEO from "../../../../components/seo"
import Layout from "../../../../components/layout"

import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../../components/cover-main/cover-main"
import { Section, Container } from "../../../../components/grid/grid"

const WfhThankYouKavlinkPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        propertyType(id: "kavling", idType: SLUG) {
          acfPropertyType {
            thankYouBanner {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  const wordPress = staticData.wordPress.propertyType

  useEffect(() => {
    if (sessionStorage.getItem("purchaseDataLayer") !== null) {
      const dataLayer = JSON.parse(sessionStorage.getItem("purchaseDataLayer"))
      dataLayer.page_title = "Thank You | Kavling"
      dataLayer.page_url = window.location.href
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(dataLayer)
      sessionStorage.removeItem("purchaseDataLayer")
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO title="Thank You | Kavling" />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <CoverMain
        // h1={data.wordPress.pageBy.title || "Image Properti"}
        img={
          wordPress?.acfPropertyType?.thankYouBanner?.sourceUrl ||
          staticData?.imgCoverMain?.childImageSharp?.fluid
        }
        alt=""
        className="cover-md"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>
      <Section className="main-thankyou">
        <Container className="container-md">
          <h2>THANK YOU!</h2>
          <p>
            Terima kasih telah tertarik dengan program kami. Anda akan dihubungi
            oleh tim kami untuk program yang ditawarkan
          </p>
        </Container>
      </Section>
    </Layout>
  )
}
export default WfhThankYouKavlinkPage
